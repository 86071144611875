<template>
  <v-container style="margin-top:60px; margin-bottom: 90px;" class="gray_bg_container"> 
      <v-container v-if="!linked||1">
         
             
              <v-img src="img/n_rozetka.png" style="margin-bottom: 15px;" max-width="100%" contain center></v-img>
              <!-- <center><v-img src="img/arch_cert_btn.png" max-width="100%" contain center @click="1"></v-img></center> -->
              <v-row class="ma-2 pa-1" >
                  <v-col cols="12" class=" pa-1 mb-5"
                    >
                    <btnsqr      
                      img="img/ico_cert_arch.png"
                    
                      @click.native="$router.push({name:'Cert_arch'})"
                    >Архів сертифікатів
                    </btnsqr></v-col>
                </v-row>
              <v-card elevation="10" class="rounded-card" style="margin-bottom:25px;margin-bottom:20px;margin-top: 1px;border-radius: 15px;height:90px;padding-left:5px;padding-top: 15px;font-size: 22px;">
                <font style="font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  
  
  color: #F07300;"
  
  >Доступний номінал: </font>500грн.<br>
              <font style="font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  
  text-decoration-line: underline;
  
  color: #F07300;"
  
  onclick="window.open('https://rozetka.com.ua/ua/pages/certificates/')"
  >Правила по використанню
             </font>
        </v-card>
  
  
  
  
        <v-img src="img/order_btn.png" max-width="100%" contain center @click="withdraw_rozetka()"></v-img>
  
  
  
  
  
  
  
  
  
  
  
  <v-card class="brand_card" style="margin-top: 25px;margin-bottom: 10px;">
        <v-row fluid style="margin-top: 25px;margin-bottom: 10px;">
              <v-col cols="1"></v-col>
              <v-col cols="10">
                  <p style="text-align: center; margin-bottom: 0;">
                <b style="
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  /* or 109% */
  text-align: center;
  
  color: #4B3C32;
  
  ">{{$t('ordered_cert')}}:</b>
              </p>
              </v-col>
              <v-col cols="1"></v-col>
        </v-row>
  
       
<!--   
         <v-container>
          <v-card  elevation="10"  class="brand_card">
   -->
            <v-row fluid class="ma-0" style="" v-for="(order, i) in orders" :key="i"> 
              <v-col cols="12">
                <span class="cert_data">
                <font style="color:#F07300;">Номер:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['code']}}</font>
              </span><br>
              <span class="cert_data">
                <font style="color:#F07300;">Діє до:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['end_date']}}</font>
              </span><br>
              
              <!-- <span>
                <b v-if="order['is_used']=='1'" style="color:#9D1C2B">Використаний</b>
                <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
  
              </span><br><br> -->
                <center>
              <a class="lnk_cert" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br><br>
              <a class="lnk_cert" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a><br><br>
              <a class="lnk_cert_arch" @click="() => arch_cert(order['eps_id'])">Архівувати сертифікат</a>
            
              </center>
              </v-col>
              <hr style="width: 95%;margin-left: 2.5%;">
                </v-row>
            <!-- </v-card>
          </v-container>    -->
  
  
      
  
  
    </v-card>


    <v-dialog persistent v-model="sms_auth_dialog" style="">
      <v-card class="simple_card">
        <v-card-title class="headline lighten-2" style="margin-bottom:25px;justify-content:center;">
          <!-- header -->
          Введіть код з SMS
          <div @click="sms_auth_dialog = false" class="close_dialog"></div>
        </v-card-title>
       
        <v-card-text>
          <!-- content -->
          <v-form class="pass_reset" @submit.prevent="withdraw_sms_check">
        <v-row fluid  class="" style="margin-bottom: -35px;">
          
          <v-col cols="12">
            <v-text-field
              v-model="auth_code"
              name="auth_code"
              v-maska="'####'" 
              label="Введіть SMS-код"
              solo
              rounded
            ></v-text-field>
          </v-col>
          
        </v-row>
        <v-row fluid class="mt-0 pt-0">
          <v-col cols="12">
            <v-btn class="btn_new" type="submit" style=""
              >Продовжити</v-btn
            >
          </v-col>
        </v-row>
          </v-form>
          
        </v-card-text>
        
      </v-card>
     <div class="popup_bottom_robot"></div>
    </v-dialog>



          <v-overlay :value="isLoading">
      <v-progress-circular indeterminate
        color="red">
        
      </v-progress-circular>
      <br />
          </v-overlay>
    </v-container>
</v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
import Btnsqr from "../components/btnsqr.vue";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,Btnsqr
  },
  data: () => ({
    
    sms_auth_dialog:false,
    auth_code:null,
    sms_e:false,
      date: new Date().toISOString().substr(0, 10),
    linked:true,
    
    amount:null,
    personal_number: null,
    oppa_account:null,
    orders:[],
    transaction_id:"",
    sms_verify_code:"",
    modal: false,
    menu2: false,
    isLoading:false,
    month_lang:{
       
        'ua':['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
      },
    
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    }, 
    title() {
        return this.$t('title')
      },

    date_locale(){
        if(this.$i18n.locale=='ru')
        {
            return 'ru-RU';
        }
        else
        {
            return 'ka-GE';
        }
    }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {},
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
    this.get_rozetka();
  },
  methods:{
    arch_cert:function(eps_id){
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "arch_cert",
          eps_id:eps_id
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {      
        console.log(resp.data);        
        this.isLoading=false;        
        this.get_rozetka();
      });
    }
    ,
    
    
    withdraw_sms_check:function(){
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "check_sms_session_withdraw",
          auth_code:this.auth_code
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        if(resp.data['code']=='OK')
        {
        this.sms_auth_dialog=false; 
         this.withdraw_rozetka();
         
        }
        else if(resp.data['code']=='wrong')
        {
          alert(this.$t('wrong_code'));

        }
        
        this.isLoading=false;
        
      });

    }
    ,

    withdraw_rozetka: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "rozetka_order_eps"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        if(resp.data['code']=='OK')
        {
          
         this.get_rozetka();
         alert(this.$t('order_success'));
        }
        else if(resp.data['code']=='no_session')
        {
          this.sms_auth_dialog=true;

        }
        else if(resp.data['code']=='insuf')
        {
          alert(this.$t('order_insuf'));

        }
        else if(resp.data['code']=='later_cert')
        {
          alert(this.$t('later_cert'));

        }
        this.isLoading=false;
        
      });
      
    },
    get_rozetka: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_rozetka_eps" 
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        this.orders=resp.data;
         this.isLoading=false;
        
      });
      
    },
    
  }
};
</script>

<i18n>
{
  "ua":{
      "title": "Обмін балів",
      
      "cert_txt":"Натисни «Замовити», щоб отримати сертифікат номіналом 500 грн.",
      "btn_order":"Замовити",
      "cert_del_txt":"Отриманий сертифікат покажи на касі у будь-якому вигляді: зображенням у iTeam, скріншотом або роздруківкою.<br><br>Слідкуй за терміном дії сертифікату! Ми не зможемо продовжити його дію або повернути бали. <br><br> <a href='https://help.rozetka.com.ua/p/21-yak-oplatyty-zamovlennya-podarunkovym-sertyfikatom/' target='_blank'>Умови використання</a>",
      "order_success":"Сертифікат замовлено! Збережіть його на телефон або зробіть скріншот.",
      "order_insuf":"Недостатньо балів",
      "ordered_cert":"Замовлені сертифікати",
      "later_cert":"Замовлення сертифікатів Rozetka тимчасово недоступне. Будь ласка, скористайтесь іншим видом «Емоцій»",
      "wrong_code":"Невірний код"
      


      

  }
}
</i18n>